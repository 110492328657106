import React from "react";
import ContactUs from "../components/homepage/contactUs/contactUs";
import Layout from "../components/layout/index";
import Seo from "../components/seo";

const index = () => {
  return (
    <Layout>
      <Seo
        slug="/contact-us/"
        title="Contact US - Teenyfy"
        description="Contact with Teenyfy team through form submission or mail info@teenyfy.com for your techncial query. Our team will get back to you soon! "
      />
      <ContactUs isPage={true} />
    </Layout>
  );
};

export default index;
